import React from 'react';
import './App.css';
import 'antd/dist/reset.css';
import { Route, BrowserRouter, Switch, Redirect } from 'react-router-dom';
import { Row, Col, Card } from 'antd';
import { Home } from "./components/home/Home";
import LoginPage from './components/Login/LoginPage';
import { AuthProvider, useAuth } from './Context/AuthContext';

const App: React.FC = () => {
  return (
    <AuthProvider>
      <BrowserRouter>
        <Switch>
          <Route exact path="/login" component={LoginPage} />
          <PrivateRoute path="/home" component={Home} />
          <Redirect from="/" to="/login" />
        </Switch>
      </BrowserRouter>
    </AuthProvider>
  );
};

const PrivateRoute: React.FC<{
  component: React.ComponentType<any>;
  path: string;
}> = ({ component: Component, ...rest }) => {
  const { user, loading } = useAuth();

  if (loading) {
    return (
      <Row align="middle" style={{ height: "100%" }}>
        <Col span={20} style={{ textAlign: 'center', top: '50%', margin: 'auto' }}>
          <Card bordered={false} loading={true} style={{ textAlign: 'center' }}>
          </Card>
        </Col>
      </Row>
    );
  }

  return (
    <Route
      {...rest}
      render={props =>
        user ? (
          <Component {...props} user={user} />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );
};

export default App;
