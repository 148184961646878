import React, { useState, useEffect } from 'react';
import "./Converter.scss";
import { Upload, Button, Modal } from 'antd';
import { SheetXLS } from '../../helpers/TemplateXLS';
import * as XLSX from "xlsx";
import {isHeaderError, isContentError, deleteDuplicate} from '../../helpers/ErrorManagement';
import LogoExcel from '../../assets/Logo_Excel.svg';
import InformationIcon from '../../assets/information.svg';

const { Dragger } = Upload;

export const Converter: React.FC = () => {

    const [ uploadedFile, setUploadedFile ] = useState<File | undefined>();
    const [ csvFile, setCsvFile ] = useState<string | undefined>();
    const [ blob, setBlob ] = useState<Blob | undefined>();
    const [ url, setUrl ] = useState<string | undefined>();
    const [ showModal, setShowModal] = useState<boolean>(false);
    let date = new Date();
    let completeDate = `${date.getDate()}/${date.getMonth()+1}/${date.getFullYear()}`;

    useEffect(() => {
        let date = new Date();
        let completeDate = `${date.getDate()}/${date.getMonth()+1}/${date.getFullYear()}`;
        if (uploadedFile) {
            let reader = new FileReader()
            reader.onload = function (event: any) {
                let data = new Uint8Array(event.target.result);
                let workbook = XLSX.read(data, {type: "array", cellStyles: true})
                const ws: XLSX.WorkSheet = workbook.Sheets[workbook.SheetNames[0]];
                let jsonData = XLSX.utils.sheet_to_json(ws);
                // @ts-ignore
                if (isHeaderError(Object.values(jsonData[11])).isError){
                    // @ts-ignore
                    alert(isHeaderError(Object.values(jsonData[11])).message);
                    window.location.reload();
                } else {
                    jsonData.splice(0, 12);
                    // @ts-ignore
                    let newJsonData = deleteDuplicate(jsonData);
                    // @ts-ignore
                    if (!isContentError(newJsonData).error) {
                        setCsvFile(() => {
                            let arrayCsv = [];
                            for (let i = 0; i < newJsonData.length; i++) {
                                // @ts-ignore
                                let rawCsv = `${newJsonData[i].__EMPTY_1};;${newJsonData[i].__EMPTY_8};${newJsonData[i].__EMPTY_9};${newJsonData[i].__EMPTY_10};${newJsonData[i].__EMPTY_11};;${newJsonData[i].__EMPTY_12};${newJsonData[i].__EMPTY_13};FR;${newJsonData[i].__EMPTY_14};${newJsonData[i].__EMPTY_16};${newJsonData[i].__EMPTY_1};1;;;;;;;;;;${newJsonData[i].__EMPTY_17};;;;;;${completeDate};`;
                                arrayCsv.push(rawCsv)
                            }
                            let csv = arrayCsv.join('\n');
                            return csv
                        });
                    } else {
                        // @ts-ignore
                        alert(isContentError(newJsonData).errorMessage);
                        window.location.reload();
                    }
                }
            }
            reader.readAsArrayBuffer(uploadedFile)
        }
    }, [uploadedFile]);

    const downloadTemplate = () => {
        let wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, SheetXLS, `Modèle-Cmd.xls`);
        console.log(wb)
        XLSX.writeFile(wb, `Modèle-Cmd.xls`, { bookType: 'xls', cellStyles: true });
    };

    useEffect(() => {
        if (csvFile) {
            setBlob(new Blob(["\ufeff", csvFile]));
        }
    },[csvFile]);

    useEffect(() => {
        if (blob) {
            setUrl(URL.createObjectURL(blob));
        }
        },[blob]);

    const handleUpload = (file : File) => {
        setUploadedFile(file);
        return false;
    }

    const handleOk = () => {
        setShowModal(!showModal)
    }

    return (
        <div className="ui-upload">
            <div className="input-container">
                <Dragger
                    name="file"
                    multiple={false}
                    accept={".xlsx, .xls"}
                    beforeUpload={handleUpload}
                    className="drag-and-drop"
                >
                    <p className="ant-upload-drag-icon">
                    <img className='logo-excel' src={LogoExcel} alt="Logo-Excel"/>                    </p>
                    <p className="ant-upload-text" style={{ color: 'white' }}>Cliquer ou glisser déposer votre fichier</p>
                    <p className="ant-upload-hint" style={{ color: 'white' }}>
                        Ce champ accepte que des '.xslx' ou '.xls'
                    </p>
                </Dragger>
            </div>
            {
                showModal ?
                    <Modal
                        title="Infos"
                        visible={showModal}
                        onOk={handleOk}
                        onCancel={handleOk}
                    >
                        <p>- L'entête du tableau doit commencer ligne 13.</p>
                        <p>- Le tableau soumit à l'application doit être semblable au modèle.</p>
                        <Button style={{backgroundColor: '#e8308a', borderColor: '#e8308a', color: 'white'}} onClick={() => downloadTemplate()}>Télécharger le modèle</Button>
                    </Modal> : null
            }
            <div className="button-container">
                {url ? <Button style={{width: 150}} onClick={() => { setTimeout(() => {window.location.reload();}, 10000)}}><img style={{width: '40%', height: '100%', marginBottom: '5px'}} src={require("../../assets/download.svg")} alt="download"/><a style={{fontStyle: 'none', color: 'white'}} href={url} download={`${completeDate}-Cmd.csv`}>Télécharger</a></Button> : null}
                <Button style={{width: '25%', display: 'flex', alignItems: 'center'}} onClick={() => handleOk()}><img style={{width: '50%', height: '100%'}} src={InformationIcon} alt="info"/>Info</Button>
            </div>

        </div>
    );
};
