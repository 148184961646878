import React, { useEffect, useState } from 'react';
import { getAuth, signInWithPopup, OAuthProvider } from 'firebase/auth';
import { useHistory } from 'react-router-dom';
import { Container, Box, Button, Snackbar, Paper, CircularProgress, Typography, Alert } from '@mui/material';
import { useAuth } from '../../Context/AuthContext';
import app from '../../back/firestoreAuthConfig';

const LoginPage: React.FC = () => {
    const auth = getAuth(app);
    const { login, user, loading } = useAuth();
    const [error, setError] = useState<string>("");
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const history = useHistory();


    useEffect(() => {
        if (user) {
            localStorage.setItem('user', JSON.stringify(user));
            history.push('/home');
        }
    }, [user,history]);

    useEffect(() => {
        const storedUser = localStorage.getItem('user');
        if (storedUser) {
            const userData = JSON.parse(storedUser);
            login(userData);
        }
    }, [login]);
    if (loading) {
        return (
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
                <CircularProgress />
                <Typography variant="h6" sx={{ ml: 2 }}>Loading...</Typography>
            </Box>
        );
    }

    const handleMicrosoftLogin = async () => {
        const provider = new OAuthProvider('microsoft.com');
        provider.setCustomParameters({
            tenant: process.env.REACT_APP_TENANT_ID || "",
        });
        try {
            const result = await signInWithPopup(auth, provider);
            const user = result.user;

            if (user.email !== null) {
                login({
                    uid: user.uid,
                    email: user.email,
                    displayName: user.displayName || "",
                    lastSignInTime: user.metadata.lastSignInTime || "", 
                    photoURL: user.photoURL || null,
                });
            history.push('/home');
        } else {
            setError("Unauthorized email.");
            setOpenSnackbar(true);
        }
    } catch (error) {
        console.error("Error signing in with Microsoft:", error);
        setError("Error signing in with Microsoft");
        setOpenSnackbar(true);
    }
};

    const handleCloseSnackbar = () => {
        setOpenSnackbar(false);
    };

    return (
        <div className="app-container">
            <div className="logo-container">
                <img src="/logoAltyor.png" alt="Altyor Logo" className="logo" />
            </div>

            <Container 
                component="main" 
                maxWidth="xs" 
                sx={{ 
                    height: 'calc(100vh - 64px)', 
                    display: 'flex', 
                    alignItems: 'center', 
                    justifyContent: 'center',
                }}
            >
                <Box sx={{ width: '100%', textAlign: 'center' }}>
                    <Paper 
                        elevation={3} 
                        sx={{ 
                            p: 4, 
                            display: 'flex', 
                            flexDirection: 'column', 
                            alignItems: 'center',
                            backgroundColor: 'rgba(246, 228, 207, 0.9)', 
                        }}
                    >
                        <Button 
                            onClick={handleMicrosoftLogin} 
                            variant="contained"
                            sx={{
                                backgroundColor: '#FF594F',
                                '&:hover': {
                                    backgroundColor: '#E54D44', 
                                },
                                color: 'white', 
                            }}
                        >
                            Login with Microsoft
                        </Button>
                    </Paper>
                </Box>
            </Container>

            <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                <Alert elevation={6} variant="filled" severity="error" onClose={handleCloseSnackbar}>
                    {error}
                </Alert>
            </Snackbar>
        </div>
    );
};

export default LoginPage;
